.booklet {
    display: flex;
    padding: 10px 5px;
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #eee;
    max-width: 360px;
    width: 100%;
    margin: 15px auto;
}

.booklet .page {
    flex: 1 0 50%;
    display: flex;
    align-items: stretch;
    padding: 15px;
}

.booklet .page > * {
    width: 325px;
    height: 445px;
    max-width: 300%;
    /*aspet*/

}

.booklet .placeholder {
    border: 1px solid #eee;
    width: 100%;
}

.join-us .desc {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    text-align: center;
    color: #2a6891;
}
.join-us .desc a {
    text-decoration: underline;
    font-weight: bold;
}

.join-us .row-item {
    flex: 1 0 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
}
.join-us .row-item {
    width: auto;
}

pred {
    background-color: #FFFFFF;
    display: block;
    margin: 30px 0 18px;
    line-height: 18px;
    font-size: 13px;
    border: 1px solid #ccc;
    padding: 15px 30px 30px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 300px;
    height: 150px;
}
.embed_lst p {
    font-size: 1.8rem;
    color: #3E9AD5;
}
.embed_lst li{
    list-style: none;
}
.embed_lst a.nav-link {
    display: inline-block;
    padding: 0;
    color: #3E9AD5;
}