
.CollapsedVideoPanel {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 25px;
    position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  }
  
  .Collapsible__VideoContentOuter{
    display: flex;
  
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  
  .Collapsible__VideoContentInner{
    display: flex;
  
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  