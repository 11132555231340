@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@600&display=swap);
.modal-popup {
  font-size: 16px;
  position: relative!important;
}
.modal-popup > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal-popup > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal-popup > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal-popup > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.booklet {
    display: flex;
    padding: 10px 5px;
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #eee;
    max-width: 360px;
    width: 100%;
    margin: 15px auto;
}

.booklet .page {
    flex: 1 0 50%;
    display: flex;
    align-items: stretch;
    padding: 15px;
}

.booklet .page > * {
    width: 325px;
    height: 445px;
    max-width: 300%;
    /*aspet*/

}

.booklet .placeholder {
    border: 1px solid #eee;
    width: 100%;
}

.join-us .desc {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    text-align: center;
    color: #2a6891;
}
.join-us .desc a {
    text-decoration: underline;
    font-weight: bold;
}

.join-us .row-item {
    flex: 1 0 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
}
.join-us .row-item {
    width: auto;
}

pred {
    background-color: #FFFFFF;
    display: block;
    margin: 30px 0 18px;
    line-height: 18px;
    font-size: 13px;
    border: 1px solid #ccc;
    padding: 15px 30px 30px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 300px;
    height: 150px;
}
.embed_lst p {
    font-size: 1.8rem;
    color: #3E9AD5;
}
.embed_lst li{
    list-style: none;
}
.embed_lst a.nav-link {
    display: inline-block;
    padding: 0;
    color: #3E9AD5;
}
body {
  font-family: 'Roboto', sans-serif;
}

header {
  background: #ffffff;
  position: absolute;
  width: 95%;
  border-radius: 0 0 50px 50px;
  left: 2.5%;
}
a {
  color: #132E50;
  text-decoration: none;
  background-color: transparent;
  transition: all 0.25s ease;
}
a:hover {
  color: #98B1CB;
}
button {
  background: #3e9ad5; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #3e9ad5 1%,#063f6f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e9ad5', endColorstr='#063f6f',GradientType=1 ); /* IE6-9 */
  border-radius: 50px;
  color: #ffffff;
    text-transform: uppercase;
    font-size:0.8em;
    transition: all 0.25s ease;
}
button:hover {
  background:#ffffff;
  border:1px solid #132E50;
  color: #132E50;
}
.border-blue{border: 1px solid #002C52;border-radius: 3px;}

.secondary-title{
  color: #3E9AD5;
  margin-top: 0.5em;
  padding: 0 10%;
}
.featured-mat .profile-text{
  padding: 0 30px;
  color: #436480;
}
.featured-mat .country-profile-subtitle{
  font-size: 1.5em;
  color:#2699FB;
}
.featured-mat .profile-info{
  color:#002c52;
}
.featured-mat .newsletterBtn{
  right: 0;
  width:146px;
}
.divider{
  border: 1px solid #e1f3ff;
}
.light-top-border{
  border-top:1px solid rgba(40, 100, 146, 0.1);
}

/* Logo */
header .logo-cont {
  position: relative;
  margin-top:-1.5em;
  max-width: 700px;
z-index: 1000;
}
.logo-cont h1 {
  font-size: 1.1em;
  color: #132E50;
}
.logo-cont h2 {
  font-size: 0.9em;
  color: #98B1CB;
  font-weight: 100;
  border-top: 1px solid #132E50;
  max-width: 450px;
}
.register-link {
  cursor: pointer;
  color: #132E50;
  border:1px solid #132E50;
  border-radius:50px;
  font-size:14px;
  padding: 5px 30px;
  margin:0 0 0 10px;
  transition: all 0.25s ease;
}
.navbar-nav{
  padding: 13px;
  margin-right: 15px;
}
.navbar-nav .dropdown-menu{border: 0;}
/*.navbar-nav .dropdown-menu ul {padding: 0px; margin-left: 10px}*/

.navbar ul li{border-bottom: 1px solid rgba(0, 0, 0, 0.15);}
.navbar-brand {
  margin: 0 1rem 0 1rem;
  display: inline-block;
  padding-top: 0rem;
  margin-bottom: 30px;
}

#main-nav-cont {
  top: 50px;
    position: absolute;
    right: -15px;
    z-index: 100;
}
#main-nav-cont .dropdown-item{padding: 0.25rem 0.6rem 0.25rem 0.6rem;}

#top-header-cont {
  margin-top:1em;
}
.top-nav {
  margin-right:2em;
}
.top-nav ul {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
}
.top-nav ul li {
  float: left;
  font-size:1em;
}


.loginBtn {
  cursor: pointer;
  color: #132E50;
}
.loginBtn:hover {
  color: #98B1CB;
  border-bottom:1px solid #98B1CB;
}
.register-link {
  cursor: pointer;
  color: #132E50;
  border:1px solid #132E50;
  border-radius:50px;
  font-size:14px;
  padding: 5px 30px;
  margin:0 0 0 10px;
  margin-right:20px;
}
.register-link:hover {
  color: #98B1CB;
  border:1px solid #98B1CB;
}
.langBtn {
  margin:0 10px;
}

/* .navbar-nav li:hover .dropdown-menu {
  display: block;
} */

.navbar {
  margin-right:1em;
}
.navbar ul li {
  font-size:1em;
}


/** HOME BANNER **/
#itc-home-banner {
  min-height: 470px;
  padding-bottom: 20px;
  width: 100%;
  background: rgba(19,46,80,1);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(19,46,80,1)), color-stop(100%, rgba(62,154,213,1)));
background: linear-gradient(to bottom, rgba(19,46,80,1) 0%, rgba(62,154,213,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#132e50', endColorstr='#3e9ad5', GradientType=0 );
display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.introCont {
  margin-top: 7em;
  margin-right: 15px;
  margin-left: 5px;
}

.intro-btn {
  background: rgba(221,9,87,1);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(221,9,87,1)), color-stop(0%, rgba(221,9,87,1)), color-stop(100%, rgba(62,154,213,1)));
  background: linear-gradient(to right, rgba(221,9,87,1) 0%, rgba(221,9,87,1) 0%, rgba(62,154,213,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dd0957', endColorstr='#3e9ad5', GradientType=1 );
border-radius:50px;
padding:10px 20px;
color:#ffffff;
border: 1px solid #378ac1;
font-size:12px;
font-weight: 100;
cursor: pointer;
transition: all 0.25s ease;
}
.intro-btn:hover {
background: transparent;
border:1px solid #ffffff;
color:#ffffff;

}

.banner-left h2 {
  font-size: 1.6em;
  text-align: left;
  color: #ffffff;
  font-weight: 600;
  margin-top:9%;
}
.banner-left p {
  font-size:0.9em;
  text-align: left;
  color: #ffffff;
  font-weight:100;
}
.banner-right {
    overflow: hidden;
    border: 5px solid #ffffff;
    border-radius: 20px;
    margin-top: 25px;
}
.form-label{
    font-size: 1.1rem;
    color: rgba(0, 44, 82, 0.8);
    font-weight: 500;
}
/* .vid-col {
  overflow: hidden;
    border: 5px solid #ffffff;
    border-radius: 20px;
} */
/** HOME TOOLS **/
.section-title {
    font-size: 2.1rem;
    color: #3E9AD5;
}
hr {
  width:75px;
  height:2px;
  background-color: #3E9AD5;
  /* margin-bottom:2em; */
}
.marketHr {
  width:75px;
}
.countrySelect {
  /* margin-top:2em; */
  width:100%;
 /* margin-left:15%;*/
  margin-bottom:2em;
  
}

.css-vj8t7z {
  background: #3e9ad5; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #3e9ad5 1%,#063f6f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e9ad5', endColorstr='#063f6f',GradientType=1 ); /* IE6-9 */
  border-radius: 50px!important;
    text-transform: uppercase;
    font-size:0.8em;
    transition: all 0.25s ease;
    height:45px;
}
.css-1492t68 {
  color: #ffffff!important;
  margin-left: 10%!important;
}


.css-xp4uvy {
    color: #ffffff!important;
    margin-left: 10%!important;
}

.featured-mat {
  border-radius:30px;
  border:1px solid #002C52;
  align-items: center;
  padding: 0;
  margin-bottom:1em;
  box-shadow: #dedede 1px 1px 1px 1px, #dedede -1px -1px 1px 1px;
}

.header-featured-mat {
  background: #3e9ad5; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #3e9ad5 1%,#063f6f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e9ad5', endColorstr='#063f6f',GradientType=1 ); /* IE6-9 */
height:30px;
border-radius:50px 50px 0 0;
margin-top:-1px;
}
.featured-mat h4 {
  color:#2699FB;
  margin-top:0.5em;
  /* padding:0 10%; */
}
.featured-mat img {
  max-width: 70%;
  margin-bottom:2em;
  max-height: 270px;
}
.mat-text {
  color:#002C52;
  margin-bottom:1em;
  padding:0 10%;
}
.btnTools {
  position: relative;
  bottom: -20px;
  cursor: pointer;
  height: 45px;
  padding: 0 2em;
  width: 70%;
  line-height: 1.2em;
  border:none;
}
.mat-tools button {
  margin-bottom:2em;
}

/** HOME LEARNING CORNER **/
#learning-intro-section, #mat-links-sidebar {
  background: rgba(19,46,80,1);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(19,46,80,1)), color-stop(100%, rgba(62,154,213,1)));
background: linear-gradient(to bottom, rgba(19,46,80,1) 0%, rgba(62,154,213,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#132e50', endColorstr='#3e9ad5', GradientType=0 );
}
#learning-intro-section h2 {
  color:#ffffff;
  padding-top:1em;
}
#learning-intro-section hr {
  background-color:#ffffff;
}
.video-title {
  color:#ffffff;
  margin-top:1em;
  font-weight: 100;
}
.learning-button {
  background: rgba(221,9,87,1);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(221,9,87,1)), color-stop(0%, rgba(221,9,87,1)), color-stop(100%, rgba(62,154,213,1)));
  background: linear-gradient(to right, rgba(221,9,87,1) 0%, rgba(221,9,87,1) 0%, rgba(62,154,213,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dd0957', endColorstr='#3e9ad5', GradientType=1 );
border-radius:50px;
padding:10px 20px;
color:#ffffff;
border:0;
font-size:12px;
font-weight: 100;
cursor: pointer;
transition: all 0.25s ease;
margin:2em 0 2em 0;
}
.lerning-button:hover {
background: transparent;
border:1px solid #ffffff;
color:#ffffff;

}
/** HOME SOCIAL **/
.social {
  border-radius:50px;
  border:2px solid #3E9AD5;
  overflow: hidden;
  height:410px;
  text-align: center;
  align-content: center;
  align-items: center;
  padding-left: 10px;
}
.socialCont {
  margin-bottom:1em;
  
}
.socialCont h3 {
  text-align:center;
  font-weight: 100;
  color:#3E9AD5;
  font-size:1.2em;
  margin-bottom:1em;
}






/** HOME TESTIMONIAL **/
#testimonial-section {
height:412px;
background: rgba(19,46,80,1);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(19,46,80,1)), color-stop(100%, rgba(62,154,213,1)));
background: linear-gradient(to bottom, rgba(19,46,80,1) 0%, rgba(62,154,213,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#132e50', endColorstr='#3e9ad5', GradientType=0 );

}
.testimonialCont {
  margin:0 10%;
}
#testimonial-section h2 {
  color:#ffffff;
  padding-top:1em;
}
#testimonial-section hr {
  background-color:#ffffff;
}
.videoTestimonial {
  margin-left:2em;
}
.testimonial {
 color: #ffffff;
 font-size:1em;
 font-weight: 100;
 padding:0 8%;
}
.signature {
  color: #ffffff;
  font-size:1em;
  font-weight: 500;
  padding:0 8%;
}
.carousel-indicators{
  bottom: -13px;
  left: 5%;
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border:1px solid #ffffff;
}
.carousel-indicators .active {
  background-color:#ffffff;
}

/** HOME FOOTER **/

.btnNewsletters {
  position: relative;
  cursor: pointer;
  height: 45px;
  padding: 0 2em;
  width: 70%;
  line-height: 1.2em;
  border:none;
}

.subscribe-title{
  font-size: 1.4rem;
  color: #3E9AD5;
  font-weight: 700;
}

#links-section .newsletter-download{
  width: 22%;
}

.email-input {
  border-radius:50px;
  border:1px solid #3E9AD5;
  width: 42.5%;
  padding:10px 20px;
  margin:1em 0 0.5em 0;
}
.newsletterBtn {
  /* position: relative; */
  cursor: pointer;
  height: 45px;
  padding: 0 2em;
  line-height: 1.2em;
  right: -25px;
  width: 16.5%;
  margin-bottom:5em;
  border:none;
}
.checkbox {
  margin-right: 0.5em;
}
.dayly-span {
  margin-top:2px;
  color:#3E9AD5;
  font-size:0.8em;
  margin-right:8em;
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #3E9AD5;
  opacity: 1; /* Firefox */
  font-size:0.8em;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #3E9AD5;
  opacity: 1; /* Firefox */
  font-size:0.8em;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #3E9AD5;
  font-size:0.8em;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: #3E9AD5;
  font-size:0.8em;
}

/** HOME FOOTER **/
footer{
  width: 100%;
  background: rgba(19,46,80,1);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(19,46,80,1)), color-stop(100%, rgba(62,154,213,1)));
background: linear-gradient(to bottom, rgba(19,46,80,1) 0%, rgba(62,154,213,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#132e50', endColorstr='#3e9ad5', GradientType=0 );
}

.footerMenu {
  margin-bottom:1em;
}
.footerMenu a {
  font-size: 0.9em;
  font-weight: 100;
}
footer i {
  color: #ffffff;
  border: 2px solid #ffffff;
  margin: 1em 1em 1em 0;
  border-radius: 50%;
  padding: 10px 10px 10px 5px;
  height: 40px;
  width: 40px;
}
footer span {
  color:#ffffff;
  font-weight: 500;
  font-size:0.8em;
}
footer a{
  color:#ffffff;
  font-weight: 500;
  font-size:1em;
  text-decoration: underline;
  padding-bottom:5px;
  margin: 10px;

}
footer p {
  color:#ffffff;
  font-weight: 100;
  font-size:0.8em;
}


/**  BANNER **/
#itc-banner {
  min-height: 250px;
  width: 100%;
  background: rgba(19,46,80,1);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(19,46,80,1)), color-stop(100%, rgba(62,154,213,1)));
background: linear-gradient(to bottom, rgba(19,46,80,1) 0%, rgba(62,154,213,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#132e50', endColorstr='#3e9ad5', GradientType=0 );
display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.itc-banner-title{
  font-size: 1.6em;
  color: #ffffff;
  font-weight: 600;
  margin-top: 10%;
}
.pointer         { cursor: pointer; }


footer .outer-cont {
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid #707072;
}

/**PAGE SIDEBAR CSS**/
.sidebar{
  padding: 25px;
  border-bottom: 1px solid #efefef;
  text-align: center;
}
.sidebar .section-title{
  color: #fff;
}
.sidebar button{
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar hr{
  background:#7ba6c1;
}

/** LEARNING CENTER PAGE CSS **/
.accordion .card-header:after {
  font-family: 'FontAwesome';  
  content: "\F068";
  float: right; 
}
.accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\F067"; 
}
section.itc-video-content .vid-col .videoWrapper {
  position: relative;
  padding-bottom: 50%;
  padding-top: 25px;
  height: 0;
  overflow: hidden;
  border: 5px solid #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 1px 1px #c3c2c2;
  width: 90%;
  margin: 0 auto;
}
section.itc-video-content .vid-col .videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
section.itc-video-content .vid-col .vid-title{
  width: 100%;
  text-align: center;
  padding: 20px;
  line-height: 24px;
  color: #132e50;
}
.guide-row .accordion > .card{
  border: 0;
}
.guide-row .accordion > .card .card-header{
  border: 1px solid #3E9AD5;
  border-radius: 50px;
  padding: 10px 20px 10px;
  color: #3E9AD5;
  background: #fff;
  margin-bottom:0;
}
.guide-row .accordion > .card .card-header:not(:first-child){
  margin-top: 15px;
}
.guide-row .accordion > .card .card-body {
  position: relative;
  top: -17px;
  border-right: 1px solid #3E9AD5;
  border-left: 1px solid #3E9AD5;
  border-bottom: 1px solid #3E9AD5;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.guide-row .accordion > .card .card-body p{
  padding-top: 30px;
  color:#132e50;
}
.user-guide-cont .Collapsible .Collapsible__contentInner{padding: 0 20px; }
.Collapsible__VideoContentInner{margin-left: 0 !important; margin-right: 0 !important;}


/** NEWS Page CSS **/

.featured-article-info h4, .article-info h4{
  color: #2699FB;
  margin-top: 0.5em;
  margin-bottom: 0;
}

.featured-article-img svg{
  border-radius: 30px;
}
.article-excerpt{overflow-y:auto; }
.article-date{
  color: #777879;
}
.news-section .card{
  border: 1px solid #002C52;
  border-radius: 40px;
}

.news-section .card-img-top{
  border-radius: 40px 40px 0 0;
}

.news .card-body{
  max-height: 300px;
  overflow-y: auto;
}

.news-section .card-footer{
  border-radius: 0 0 40px 40px;
  border-top: 1px solid rgba(0, 44, 82, 0.5);
}


/** DATA AVAILABILITY Page CSS **/

.css-1hwfws3{
  overflow: auto !important;
  display: inline-flex !important;
  width: 100% !important;
  flex-wrap:nowrap !important;
  -webkit-flex-wrap:nowrap !important;
}
.css-xwjg1b{
  min-width: 77px !important;
  border-radius: 10px !important;
  background-color: #f4f4f4 !important;
}

.data-cont{
  /* border: 1px solid #002C52; */
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: auto;
  max-height: 300px;
  min-height: 300px;
  width: 100%;
  
}
.data-results h2 {
  font-size: 1rem;
}

.country-list, .data-list{
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
}
.data-val{
  padding-bottom: 3px;
  padding-top: 3px;
  border-bottom: 1px solid rgba(16, 52, 80, 0.1);
  color: #212529;
}
.country-list .data-val:last-child, .data-list .data-val:last-child{border: none;}

.data-list .data-val{text-align: right;color: #949393;}

/* Country profile page CSS */
.country-profile-row{
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  border: 1px solid rgba(40, 100, 146, 0.1);
}

.country-profile-row h4>img{
  background: #2699fb;
  width: 25px;
  height: 20px;
  vertical-align: inherit;
  margin-right: 10px;
}
section#itc-profile-content .country-profile-row .usd:before {
  content: "\F155";
}
section#itc-profile-content .country-profile-row .icon:before {
  font-family: "Font Awesome 5 Free";
  font-size: 40px;
  font-weight: 900;
  color: #009ee0;
  position: relative;
  top: 4px;
  left: -2px;
  right: 0;
  margin: auto;
}
section#itc-profile-content .country-profile-row .country-profile-subtitle {
  font-size: 36px;
  line-height: 42px;
}
section#itc-profile-content .country-profile-row .profile-info {
  margin: 10px 0;
  text-align: center;
  position: relative;
  float: left;
  width: 100%;
  font-size: 20px;
}
section#itc-profile-content .country-profile-row .profile-info .data {
  font-size: 32px;
  margin: 0 5px;
  position: relative;
  display: inline-block;
  line-height: 64px;
}


.tooltip-title{
  font-size: 14px;
}

/* Responsive screen css */

@media (min-width: 576px){
  section#itc-profile-content .country-profile-row .country-profile-subtitle {
    font-size: 28px;
    line-height: 32px;
  }
  section#itc-profile-content .country-profile-row .profile-info .data {
      font-size: 36px;
      margin: 0 10px;
      line-height: 72px;
  }
  section#itc-profile-content .country-profile-row .profile-info {
    margin: 10px;
    font-size: 22px;
  }
}

@media (max-width: 576px){
  #itc-banner{min-height: 212px !important;}
  .itc-banner-title{
    /* position: relative;
    top: 40px; */
    margin-top: 30%;
  }
  .small-center{text-align: center!important;}
}


@media (max-width: 768px) {
  .carousel-indicators{bottom: 0;}
  .featured-article-info{text-align: center;}

  .small-center{text-align: center!important;}
  
  .navbar-brand {
    border-right: 0;
    margin-top: 1.5em;
    margin-bottom: 5px;
}
#testimonial-section{
    height:450px;
}

  .banner-left {
    margin-bottom:3em;
  }
  .banner-right {
    margin-bottom:3em;
  }
  .featured-mat {
    margin-bottom:3em;
  }
  .video-title {
    margin-bottom:2em;
  }
  #links-section .newsletter-download{
    width: 100%;
    flex: auto;
    margin-bottom: 10px;
  }
  .email-input{width: 100%;}
  .newsletterBtn{width:50%;}
}

@media (max-width: 992px) {
  .noneMobile {
    display: none;
  }
  button.navbar-toggler{
    position: relative;
    top: 4px;
    left: -8px;
    flex-basis: auto;    
  }
  .navbar-toggler-icon{
    padding: 5px;
  }
  
  .navbar-collapse{
    position: relative;
    left: -138px;
    top: 3px;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #327cb0;
    margin-top: 9px;
    flex-basis:auto;
  }
}

@media (max-width:999px) {
  .navbar{
    /* background: #fff; */
    z-index: 2;
  }

  #links-section .newsletter-download{
    margin-right: 10px;
  }
  .btnNewsletters, .btnTools{
    width: 100% !important;
  }
  .newsletterBtn, .newsletterBtn{width: 51%;}
  
}

@media (min-width: 992px){
  section.itc-video-content .vid-col .vid-title {
      font-size: 18px;
      line-height: 28px;
  }
}
@media (max-width: 1200px){
  #mat-links-sidebar .intro-btn{
    width: 100% !important;
  }
  .logo-cont h2 {
    display: none;
  }
}
/* Only IE CSS  */
@media screen\0{	
  .itc-banner-title {
    margin-top:150px;
	}	
}

.data-results table{
  width: 100%;
}

.alignRight{
  text-align: right;
}

.imgspinner{
  width: 100%;
  
  text-align: center;
    margin-top: 18%;
}

.csv-btn{
  padding: 5px 20px 0 0;
    text-align: right;
    width: 100%;
    float: right;
    width: 50px;
}

.csv-title{
  padding: 5px 0 0 20px;
  
  float: right;
  text-align: center;
}

.csv-title a {
  color: #ffffff;
}

.csv-title a:hover {
  color: #98B1CB;
}

.navbar-logo:link{
  text-decoration: none;
  z-index: 1000;
  color: #132E50;
}
.navbar-logo:hover{
  text-decoration: none;
  z-index: 1000;
  color: #132E50;
}
.navbar-logo:visited{
  text-decoration: none;
  z-index: 1000;
  color: #132E50;
}
.navbar-logo:active{
  text-decoration: none;
  z-index: 1000;
  color: #132E50;
}

.contentLink{
  color: #0052cc !important;
}





.navbar-light .navbar-nav .nav-link {
  color: rgb(64, 64, 64);
}



.btco-hover-menu a ,  .navbar > li > a {
  text-transform: none;
  padding: 10px 15px;
  outline: 0;
}





.btco-hover-menu .active a,
.btco-hover-menu .active a:focus,
.btco-hover-menu .active a:hover,

.btco-hover-menu li a:focus ,
.navbar>.show>a,  .navbar>.show>a:focus,  .navbar>.show>a:hover{
  color: #000;
  background: transparent;
  outline: 0;
}

/*.btco-hover-menu li a:hover{
  background: #e8e8e8
}
*/


/*submenu style start from here*/


.dropdown-menu {
  padding: 0px 0; 
  margin: 0 0 0; 
  border: 0px solid transition !important;
  border: 0px solid rgba(0,0,0,.15);	
  border-radius: 0px;
  box-shadow: none !important;

}

/*.nav-item{border: none !important}*/

/*first level*/
.btco-hover-menu .collapse ul > li:hover > a{background: #fff;}
.btco-hover-menu .collapse ul ul > li:hover > a, .navbar .show .dropdown-menu > li > a:focus, .navbar .show .dropdown-menu > li > a:hover{background: #fff;}
/*second level*/
.btco-hover-menu .collapse ul ul ul > li:hover > a{background: #fff;}


/*third level*/
.btco-hover-menu .collapse ul ul, .btco-hover-menu .collapse ul ul.dropdown-menu{background:#fff;    margin-left: 1px;}
.btco-hover-menu .collapse ul ul ul, .btco-hover-menu .collapse ul ul ul.dropdown-menu{background:#fff}
.btco-hover-menu .collapse ul ul ul ul, .btco-hover-menu .collapse ul ul ul ul.dropdown-menu{background:#fff}

/*Drop-down menu work on hover*/
.btco-hover-menu{background: none;margin: 0;padding: 0;min-height:20px}

@media only screen and (max-width: 991px) {
  .btco-hover-menu .show > .dropdown-toggle::after{
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
  }
}
@media only screen and (min-width: 991px) {

  .btco-hover-menu .collapse ul li{position:relative;}
  .btco-hover-menu .collapse ul li:hover> ul{display:block}
  .btco-hover-menu .collapse ul ul{position:absolute;top:100%;left:0;min-width:150px;display:none}
  /*******/
  .btco-hover-menu .collapse ul ul li{position:relative}
  .btco-hover-menu .collapse ul ul li:hover> ul{display:block}
  .btco-hover-menu .collapse ul ul ul{position:absolute;top:0;left:100%;min-width:150px;display:none}
  /*******/
  .btco-hover-menu .collapse ul ul ul li{position:relative}
  .btco-hover-menu .collapse ul ul ul li:hover ul{display:block}
  .btco-hover-menu .collapse ul ul ul ul{position:absolute;top:0;left:-100%;min-width:150px;display:none;z-index:1}

}
.dropdown-menu > .nav-item > .nav-link{
  padding: 0.25rem 0.6rem 0.25rem 0.6rem !important;
}

.videoTestimonial  img {
  width: auto ;
  max-width: 100% ;
  height: auto ;

}

.sponsors ul {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.sponsors li {
  text-align: center;
  list-style-type: none;
    margin: 0 15px;
}

.sponsors .note {
  text-align: center;
}

/*EMBEDDED STYLES*/



.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.section, .section-region, .section-sector {
    padding-top: 0;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #fff;
    height: 1235px;
}

#country-profile-embedded-content, #region-profile-embedded-content, #sector-profile-embedded-content {
    height: 675px;
    display: flex;
    align-content: center;
}

#country-profile-embedded-content p, #region-profile-embedded-content p, #sector-profile-embedded-content p{
    font-family: 'Public Sans', sans-serif;
    font-size: 18px;
    color: #002b54;
}

a {
    text-decoration: none;
    color: #1A237E;
}
a.mat-survey-lnk {
  font-size: 18px;
  font-weight: 600;
}
p {
    color: #002b54;
    font-family: 'Public Sans', sans-serif;
}

.section-title {
    padding-top: 24px;
    padding-bottom: 24px;
    margin-top: 48px;
    margin-bottom: 32px;
    color: #002b55;
    font-size: 36px;
    font-family: 'Zilla Slab', serif;
}
.section h5 {
    color: #2196F3;
}
.section h4 {
    color: #0D47A1;
}

/*.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: #002c52;
    color: #fff;
    border-radius: 20px;
    font-family: 'Roboto', monospace;
    font-size: bold;
}*/

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    border-bottom: 3px solid #d80052;
    color: #002b55;
    font-family: 'Public Sans', sans-serif;
    font-size: 21px;
    background-color: #fff;
    border-radius: 0;
}

.nav-pills .nav-link {
    color: #002b55;
    font-family: 'Public Sans', sans-serif;
    font-weight: bold;
    font-size: 21px;
    padding: 0.9rem 1.3rem;
    border-radius: 0;
}
.nav-pills .nav-link:hover {
    cursor: pointer;
    border-bottom: 3px solid #d80052;
}
.nav-link {
    display: block;
    padding: 0.9rem 2.3rem;
    font-family: 'Roboto', monospace;
    font-size: bold;
}

.card-number {
    border: none;
    margin-bottom: 5%;
    padding: 13px 14px 24px 19px;
    box-shadow: inset 0 3px 2px 0 rgba(0, 0, 0, 0.25);
    background-color: #eaeef1;
    border-radius: 0;
    height: 183px;
    margin: 2%;
}

.card-number .card-title {
    color: #002b54;
    margin: 0 18px 12px 0;
    font-family: 'Public Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    width: 100%
}

.card-number .card-value {
    color: #b8014c;
    font-size: 36px;
    font-family: 'Public Sans', sans-serif;
    font-weight: bold;
    width: 100%;
    height: 49px;
    text-align: left;
}

.card-number .card-currency {
    display: inline-block;
    color: #b8014c;
    font-size: 24px;
    font-family: 'Public Sans', sans-serif;
    font-weight: bold;
    margin-right: 5px;
    text-align: left;
}

.card-number .card-year {
    display: inline-block;
    color: #009de8;
    font-size: 21px;
    font-family: 'Public Sans', sans-serif;
    font-weight: bold;
}
.card-number .card-source {
    color: #002b54;
    font-family: 'Public Sans', sans-serif;
    font-size: 14px;
    width: 150px;
    height: 19px;
    font-weight: bold;
    text-align: left;
    letter-spacing: normal;
    font-stretch: normal;
    line-height: 1.35;
}
/**/
.card-info {
    border: none;
    font-size: 12px;
}

.card2 {
    border-left: 5px solid #d80052;
    font-family: 'Roboto', monospace;
    font-size: bold;
    font-size: 12px;
}

.card-t {
    font-size: 12px;
    font-size: bold !important;
    color: #d80052 !important;
    font-family: 'Roboto', monospace;
}

.card-v {
    color: #212121;
    font-size: 22px;
}

.chart {
    border: 1px solid #002c52;
    border-radius: 10px;
    width: 100%;
    height: auto;
}

.card-footer{
    border-radius: 0 !important;
    border: 0 !important;
    background: none !important;
}
.card-other {
    background-color: #fff;
    padding: 2%;
    border: 1px solid #adbbc9;
    border-radius: 0;
    text-align: center;
}

.card-other .card-value {
    color: #b8014c;
    font-size: 36px;
    font-family: 'Public Sans', sans-serif;
    font-weight: bold;
    width: 100%;
    height: 49px;
}

.btn-embedded-primary {
    background-color: #b8014c;
    color: #fff;
    border-color: #b8014c;
    border-radius: 2px;
    font-family: 'Public Sans', sans-serif;
    font-size: 17px;
    width: 100%;
    font-weight: bold;
}

.btn-embedded-primary:hover {
    background-color: #b8014c;
    color: #fff;
    border-color: #b8014c;
    border-radius: 2px;
    font-family: 'Public Sans', sans-serif;
    font-size: 17px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    font-weight: bold;
}

.btn-embedded-secondary {
    background-color: #fff !important;
    border: 1px solid #b8014c;
    color: #b8014c;
    border-radius: 2px;
    font-family: 'Public Sans', sans-serif;
    font-size: 21px;
    margin-top: 48px;
    margin-bottom: 72px;
    font-weight: bold;
}

.section .btn-embedded-secondary { /*SPECIFIC FOR COUNTRY VIEW*/
    background-color: #fff !important;
}

.btn-embedded-secondary:hover {
    background-color: #fff;
    border: 1px solid #b8014c;
    color: #b8014c;
    border-radius: 2px;
    font-family: 'Public Sans', sans-serif;
    font-size: 21px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    font-weight: bold;
}
#country-nav-tabs{
    margin-top: 48px;
    margin-bottom: 32px;
}
.embedded #dropdown-menu-align-right {
    width: 291px;
    height: 56px;
    background-color: #002b54 !important;
    color: #fff !important;
    border-radius: 0;
}
.embedded #dropdown-menu-align-right:hover {
    color: #fff;
}

.embedded #DropDown button {
    height: 56px;
    background: #002b54 !important;
    color: #fff !important;
    border-radius: 0;
    /*width: 343px;*/
    width: 706px;
    padding: 0 5px 5.3px;
    border: 2px solid #002b54;
    text-transform: capitalize;
    font-weight: bold;
    font-family: 'Public Sans', sans-serif !important;
    font-size: 19px;
    line-height: 1.3;
    letter-spacing: 0.25px;
}
.embedded .dropdown-toggle::after {
    margin-left: 21.255em !important;
    vertical-align: 0.155em;
}
.embedded .btn-embedded-tertiary-dropdown:hover {
    color: #fff !important;
}
.embedded .dropdown-item.active, .dropdown-item:active {
    background-color: #fff !important;
    color: #002b54;
}
.embedded .dropdown-menu {
    width: 706px;
    padding: 0 0 5.3px;
    border: 4px solid #002b54;
}
.embedded .dropdown-item {
    font-size: 19px;
    font-family: 'Public Sans', sans-serif;
    color: #002b54;
    padding: 16px;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: 0.25px;
}
    .embedded .dropdown-item:hover {
        color: #002b54;
    }
.highcharts-container {
    margin: 0 auto;
}
.highcharts-root{
    width: 100% !important;
}
.highcharts-background{
    height: 100% !important;
}
.div-chart {
    display: flex;
    justify-content: center;
    width: 100%;
}
.content-description-title {
    font-family: 'Public Sans', sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.25px;
    /*text-align: left;*/
    color: #002b54;
}
.content-description-top{
    padding-left: 15px;
    padding-right: 15px;
}
.legend {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    border-radius: 24px;
    border: none;
    background: #fff;
    color: #002b54;
    opacity: 0.5;
    font-family: 'Public Sans', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
}
.content-link {
    color: #0b75d3;
    font-family: 'Public Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
}
.content-link:hover{
    text-decoration: none;
}
.vl {
    border-left: 2px solid #c8c8c8;
    margin-left: 4%;
    margin-right: 4%;
    height: 20px;
}
/*TREEMAP D3 STYLE*/
.treemap, .barchart {
    width: 100%;
    height: 100%;
}
.box {
    position: absolute;
    text-align: left;
    width: 200px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    font: 14px sans-serif;
    background: #fff;
    pointer-events: none;
    font-weight: bold;
    border: 1px solid #adbbc9;
    color: #002b54;
}
/*BARCHART D3 STYLE*/
.box-tradepartners {
    position: absolute;
    text-align: left;
    width: 300px;
    padding: 5px;
    font: 12px 'Public Sans', sans-serif;
    background: #fff;
    pointer-events: none;
    border: 1px solid #adbbc9;
}
.box ul {
    padding-left: 15px;
}

.box li {
    list-style-type: none;
    font-family: 'Roboto', sans-serif;
    margin: 5px;
}
#box-treemap {
    width: 300px;
}
.treemap-product {
    /*border: solid 1px #000;*/
    line-height: 20px;
    overflow: hidden;
    position: absolute;
    text-indent: 2px;
    cursor: pointer;
}
.treemap-product p {
/*    font-family: 'Maven Pro', sans-serif !important;
    font-size: 14px !important;
    color: #fff !important;*/
    margin: 2%;
}
.small-p-treemap{
    font-size: 12px !important;
}
.p-treemap-dark{
    color: #002b54 !important;
}
h3.boxCountries-lbl {
    font-family: 'Maven Pro', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: #009de8;
}

.box-treemap-value {
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
    color: #b8014c;
    font-size: 18px;
}

h4.boxCountries-lbl-light {
    font-family: 'Maven Pro', sans-serif;
    font-size: 14px;
    font-weight: 100;
}

h4.boxCountries-lbl {
    font-family: 'Maven Pro', sans-serif;
    font-size: 14px;
    font-weight: bold;
}

.partners-title {
    font-weight: bold;
    font-size: 20px;
    font-family: 'Public Sans', sans-serif;
    color: #002b54;
}

.axis{
    font-weight: bold;
}

/*EMBEDDED Responsive*/
/*XXS*/
@media (max-width: 425px){
    /*    .section {
        height: 3376px;
    }
    #country-profile-embedded-content {
        height: 2126px;
    }*/

/*    .section-region {
        height: 1282px;
    }

    #region-profile-embedded-content {
        height: 848px;
    }

    .section-sector {
        height: 1248px;
    }

    #sector-profile-embedded-content {
        height: 820px;
    }*/

    .card-number .card-value {
        font-size: 36px;
    }

    .div-chart {
        margin-bottom: 5%;
    }

    #others {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    #macmap .chart {
        width: 65%;
    }

    .btn-embedded-primary {
        font-size: 11px;
    }

    .btn-embedded-secondary {
        font-size: 11px;
        margin-top: 56px;
    }

    .btn-embedded-primary:hover {
        font-size: 11px;
    }

    .btn-embedded-secondary:hover {
        font-size: 11px;
    }

    .section-title {
        padding-top: 16px;
        padding-bottom: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .embedded #DropDown button {
        width: 300px;
    }

    .embedded .dropdown-menu {
        width: 300px;
    }

    .embedded .dropdown-toggle::after {
        margin-left: 2em !important;
        vertical-align: 0.155em;
    }

    .highcharts-container {
        width: 300px !important;
    }

}
/*XS*/
@media (min-width: 426px) and (max-width: 576px) {
    /*    .section {
        height: 3376px;
    }
    #country-profile-embedded-content {
        height: 2126px;
    }*/

/*    .section-region {
        height: 1282px;
    }

    #region-profile-embedded-content {
        height: 848px;
    }

    .section-sector {
        height: 1248px;
    }

    #sector-profile-embedded-content {
        height: 820px;
    }*/

    .card-number .card-value {
        font-size: 36px;
    }

    .div-chart {
        margin-bottom: 5%;
    }

    #others {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    #macmap .chart {
        width: 65%;
    }

    .btn-embedded-primary {
        font-size: 11px;
    }

    .btn-embedded-secondary {
        font-size: 11px;
        margin-top: 56px;
    }

    .btn-embedded-primary:hover {
        font-size: 11px;
    }

    .btn-embedded-secondary:hover {
        font-size: 11px;
    }

    .section-title {
        padding-top: 16px;
        padding-bottom: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .embedded #DropDown button {
        width: 343px;
    }

    .embedded .dropdown-menu {
        width: 343px;
    }

    .embedded .dropdown-toggle::after {
        margin-left: 2em !important;
        vertical-align: 0.155em;
    }

    .highcharts-container {
        width: 340px !important;
    }

}
/*SM*/
@media (min-width: 577px) and (max-width: 768px) {
/*    .section {
        height: 2513px;
    }
    #country-profile-embedded-content {
        height: 1646px;
    }*/

/*    .section-region {
        height: 1235px;
    }
    #region-profile-embedded-content {
        height: 800px;
    }

    .section-sector {
        height: 1211px;
    }
    #sector-profile-embedded-content {
        height: 775px;
    }*/

    .card-number .card-value {
        font-size: 36px;
    }
    .div-chart {
        margin-bottom: 3%;
    }
    .chart {
        width: 100%;
    }
    #macmap .chart {
        width: 65%;
    }

    #others{
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .section-title {
        padding-top: 16px;
        padding-bottom: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .btn-embedded-primary {
        font-size: 16px;
    }

    .btn-embedded-secondary {
        font-size: 16px;
        margin-top: 56px;
    }

    .btn-embedded-primary:hover {
        font-size: 16px;
    }

    .btn-embedded-secondary:hover {
        font-size: 16px;
    }
    .embedded #DropDown button {
        width: 343px;
    }

    .embedded .dropdown-menu {
        width: 343px;
    }
    .embedded .dropdown-toggle::after {
        margin-left: 1em !important;
        vertical-align: 0.155em;
    }
    .highcharts-container {
        width: 340px !important;
    }
}
/*MD*/
@media (min-width: 769px) and (max-width: 992px) {
/*    .section{
        height: 2098px;
    }
    #country-profile-embedded-content {
        height: 1459px;
    }*/

/*    .section-region {
        height: 1272px;
    }
    #region-profile-embedded-content {
        height: 782px;
    }

    .section-sector {
        height: 1228px;
    }
    #sector-profile-embedded-content {
        height: 736px;
    }
*/
    .div-chart{
        margin-bottom: 5%;
    }
    .chart {
        width: 65%;
    }
    #macmap .chart{
        width: 45%;
    }
    .card-number .card-value {
        font-size: 26px;
    }
    .card-number .card-title {
        font-size: 14px;
    }
    .embedded .dropdown-toggle::after {
        margin-left: 21.255em !important;
    }
}
/*LG*/
@media (min-width: 993px) and (max-width: 1200px) {
/*    .section {
        height: 1632px;
    }
    #country-profile-embedded-content {
        height: 934px;
    }*/

/*    .section-region {
        height: 972px;
    }
    #region-profile-embedded-content {
        height: 482px;
    }

    .section-sector {
        height: 972px;
    }
    #sector-profile-embedded-content {
        height: 482px;
    }*/

    #macmap .chart {
        width: 70%;
    }
    .card-other{
        height: 95%;
    }

}
/*XL*/
@media (min-width: 1201px) {
    /*.section {*/
        /*height: 1502px;
    }
    #country-profile-embedded-content {*/
        /*height: 803px;
    }*/

    /*.section-region {
        height: 975px;
    }
    #region-profile-embedded-content {*/
        /*height: 560px;*/
        /*height: 486px;
    }

    .section-sector {
        height: 975px;
    }
    #sector-profile-embedded-content {*/
        /* height: 560px;*/
        /*height: 486px;
    }*/

    #macmap .chart {
        width: 70%;
    }
    .card-other {
        height: 95%;
    }
}

.CollapsedVideoPanel {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 25px;
    position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  }
  
  .Collapsible__VideoContentOuter{
    display: flex;
  
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  
  .Collapsible__VideoContentInner{
    display: flex;
  
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
